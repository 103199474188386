<script lang="ts" setup>
import { watch } from 'vue'
import RoomieInput from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import useLocationAutocomplete from '@/composables/BrandedV2/use-location-autocomplete'
import type { LocationAutocompleteComponentModule } from '@/components/BrandedV2/LocationAutocomplete/types'

const props = withDefaults(defineProps<LocationAutocompleteComponentModule.Props>(), {
  placeholder: 'Buscar...',
  minChars: 3,
  containerClass: '',
  inputContainerClass: ''
})

const emit = defineEmits(['update:modelValue', 'select'])

const {
  inputValue,
  suggestions,
  showSuggestions,
  highlightedIndex,
  iconByType,
  isLoading,
  onInput,
  selectSuggestion,
  onArrowDown,
  onArrowUp,
  onEnter,
  onFocus,
  onFocusOut,
  onResetValue
} = useLocationAutocomplete({ modelValue: props.modelValue, minChars: props.minChars, countryCode: props.countryCode, displayValue: props.displayValue }, emit)

watch(() => props.modelValue, (newVal) => {
  inputValue.value = newVal
})

</script>

<script lang="ts">
export default {
  name: 'LocationAutocomplete'
}
</script>

<template>
  <div class="location-autocomplete">
    <div :class="containerClass">
      <div :class="inputContainerClass">
        <RoomieInput
          id="autocomplete"
          :value="modelValue"
          type="text"
          :placeholder="placeholder"
          autocomplete="off"
          icon-placement="right"
          :is-internal-validated="false"
          required
          @input="onInput"
          @keydown.down.prevent="onArrowDown"
          @keydown.up.prevent="onArrowUp"
          @keydown.enter.prevent="onEnter"
          @focus="onFocus"
          @focusout="onFocusOut">
          <ClientOnly>
            <div
              v-show="inputValue"
              class="location-autocomplete__reset-input"
              @click="onResetValue">
              <RoomieIcon custom-styles="block w-24 h-24">
                <CloseIcon />
              </RoomieIcon>
            </div>
          </ClientOnly>
        </RoomieInput>
      </div>

      <slot />
    </div>

    <ul
      v-if="!isLoading && inputValue && showSuggestions"
      class="location-autocomplete__result-list">
      <li
        v-for="(item, index) in suggestions"
        :key="index"
        :class="['location-autocomplete__item ', { 'bg-pine-100': index === highlightedIndex, }]"
        @mousedown.prevent="selectSuggestion(item)">
        <RoomieIcon custom-styles="block w-24 h-24 text-pine-600">
          <component :is="iconByType[item.type]" />
        </RoomieIcon>

        <div class="flex flex-col justify-center">
          <p class="hl-3-sb">
            {{ item.location.placeName }}
          </p>

          <p class="body-2-m">
            {{ item.location.fullLocation }}
          </p>
        </div>
      </li>
    </ul>

    <div
      v-show="isLoading"
      class="location-autocomplete__loading-container">
      <div>
        <img
          src="https://assets.lahaus.com/static/images/loading.gif"
          width="60"
          height="24"
          alt="loading">
      </div>
    </div>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
