import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { slugify } from '@/utils/strings'
import type { SuggestionClient } from '@/services/listingDataService/types/suggestion'
import type { FormSearchFiltersComponentModule } from '@/components/BrandedV2/FormSearchFilters/types'
import { LOCATION_TYPE_LIST } from '@/services/listingDataService/enums'

const useRealEstateSearch = (currentPath: string, emit: (...args: any) => void) => {
  const searchFiltersStore = useSearchFiltersStore()
  const { currentFilters, selectedFilters } = storeToRefs(searchFiltersStore)

  const searchTerm = ref('')

  const buildLocation = (data: any) => {
    const { type } = data

    const typeMap: any = {
      city: ['city'],
      zone: ['city', 'zone'],
      neighborhood: ['city', 'zone', 'neighborhood']
    }

    const location: any = {}
    typeMap[type]?.forEach((key: any) => {
      if (data[key]) location[key] = slugify(data[key])
    })

    return location
  }

  const onSubmit = async () => {
    const location = selectedFilters.value.location || null
    if (searchTerm.value) {
      await searchFiltersStore.updateFilters({ ...currentFilters.value, location } as any, currentPath)
    }
  }

  const updateLocationFilter = (suggestion: SuggestionClient.Suggestion | null) => {
    selectedFilters.value.location = suggestion ? buildLocation(suggestion) : null
  }

  const suggestionSelected = (suggestion: SuggestionClient.Suggestion | null) => {
    if (!suggestion) {
      updateLocationFilter(suggestion)
      return
    }

    if (suggestion.type === LOCATION_TYPE_LIST.RESIDENTIAL_COMPLEX && suggestion.residentialComplex) {
      emit('residential-complex-selected', {
        path: suggestion.residentialComplex.path,
        residentialComplex: suggestion.residentialComplex
      })

      return
    }

    updateLocationFilter(suggestion)
  }

  const filtersChange = async (filters: FormSearchFiltersComponentModule.Filters) => {
    await searchFiltersStore.updateFilters(filters)
    emit('filters-change', filters)
  }

  return {
    searchTerm,
    buildLocation,
    filtersChange,
    onSubmit,
    updateLocationFilter,
    suggestionSelected
  }
}

export default useRealEstateSearch
