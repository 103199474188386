import { ref } from 'vue'
import { TypesenseComposableModule } from '@/composables/useTypesense/types'
import { REQUEST_STATUS_LIST } from '@/utils/request-status'
import { ACTION_LIST } from '@/composables/useTypesense/enums'

const useTypesense = (): TypesenseComposableModule.VueHookInstance => {
  const requestStatus = ref<REQUEST_STATUS_LIST>(REQUEST_STATUS_LIST.IDLE)
  const data = ref<TypesenseComposableModule.ResidentialComplexResponse>(null)
  const requestError = ref(null)
  const currentAction = ref<ACTION_LIST>(null)
  const fetchResidentialComplex = async (filters: TypesenseComposableModule.ResidentialComplexFilters): Promise<TypesenseComposableModule.ResidentialComplexResponse> => {
    requestStatus.value = REQUEST_STATUS_LIST.LOADING
    currentAction.value = ACTION_LIST.FETCH_RESIDENTIAL_COMPLEX
    try {
      data.value = await $fetch('/api/buyer-front/listing/residential-complex', {
        method: 'POST',
        body: { ...filters }
      })

      requestStatus.value = REQUEST_STATUS_LIST.SUCCESS
      requestError.value = null
    } catch (error) {
      requestStatus.value = REQUEST_STATUS_LIST.ERROR
      data.value = null
      requestError.value = error
    }
  }

  const fetchSuggestionList = async (filters: TypesenseComposableModule.SuggestionRequestParams): Promise<TypesenseComposableModule.SuggestionResponse> => {
    requestStatus.value = REQUEST_STATUS_LIST.LOADING
    currentAction.value = ACTION_LIST.FETCH_SUGGESTION_LIST
    try {
      data.value = await $fetch('/api/buyer-front/listing/suggestions', {
        method: 'POST',
        body: { ...filters }
      })

      requestStatus.value = REQUEST_STATUS_LIST.SUCCESS
      requestError.value = null
    } catch (error) {
      requestStatus.value = REQUEST_STATUS_LIST.ERROR
      data.value = null
      requestError.value = error
    }
  }

  return {
    currentAction,
    data,
    fetchResidentialComplex,
    fetchSuggestionList,
    requestError,
    requestStatus
  }
}

export default useTypesense
