import { ref } from 'vue'

export function useDebounce<T extends (...args: any[]) => void>(fn: T, delay: number = 300) {
  const timeout = ref<number | null>(null)

  const debouncedFn = (...args: Parameters<T>) => {
    if (timeout.value !== null) clearTimeout(timeout.value)
    timeout.value = window.setTimeout(() => {
      fn(...args)
    }, delay)
  }

  return debouncedFn
}
