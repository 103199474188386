import { computed, ref, watch } from 'vue'
import HomeIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/home.svg'
import TourIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/tour.svg'
import { useDebounce } from '@/composables/useDebounce'
import useTypesense from '@/composables/useTypesense'
import { LOCATION_TYPE_LIST } from '@/services/listingDataService/enums'
import type { LocationAutocompleteComposableModule } from '@/composables/BrandedV2/use-location-autocomplete/types'
import type { SuggestionClient } from '@/services/listingDataService/types/suggestion'
import { REQUEST_STATUS_LIST } from '@/utils/request-status'
import { ACTION_LIST } from '@/composables/useTypesense/enums'

const useLocationAutocomplete = (
  params: LocationAutocompleteComposableModule.Params,
  emit: (event: 'update:modelValue' | 'select', ...args: any[]) => void
): LocationAutocompleteComposableModule.UseLocationAutocompleteReturn => {
  const { currentAction, data, requestStatus, fetchSuggestionList } = useTypesense()

  const iconByType: Record<LOCATION_TYPE_LIST, string> = {
    [LOCATION_TYPE_LIST.CITY]: TourIcon,
    [LOCATION_TYPE_LIST.RESIDENTIAL_COMPLEX]: HomeIcon,
    [LOCATION_TYPE_LIST.ZONE]: TourIcon,
    [LOCATION_TYPE_LIST.NEIGHBORHOOD]: TourIcon
  }

  const inputValue = ref<string>(params.modelValue)
  const suggestions = ref<SuggestionClient.Suggestion[]>([])
  const showSuggestions = ref<boolean>(false)
  const highlightedIndex = ref<number>(-1)
  const isSuggestionSelected = ref<boolean>(false)

  const isLoading = computed(() => (requestStatus.value === REQUEST_STATUS_LIST.LOADING && currentAction.value === ACTION_LIST.FETCH_SUGGESTION_LIST))

  watch(data, (newValue) => {
    suggestions.value = newValue as SuggestionClient.Suggestion[]
    showSuggestions.value = newValue.length > 0
    highlightedIndex.value = -1
  })

  const getSuggestions = useDebounce(async (value: string) => {
    if (value.length >= params.minChars) {
      await fetchSuggestionList({
        query: value,
        countryCode: params.countryCode.toUpperCase(),
        includeProjects: true
      })
    } else {
      suggestions.value = []
      showSuggestions.value = false
    }
  }, 300)

  const onInput = (value: string) => {
    inputValue.value = value
    emit('update:modelValue', inputValue.value)
    getSuggestions(inputValue.value)
  }

  const selectSuggestion = (item: SuggestionClient.Suggestion) => {
    locationFormatted(item)
    emit('update:modelValue', inputValue.value)
    emit('select', item)
    showSuggestions.value = false
    isSuggestionSelected.value = true
  }

  const onArrowDown = () => {
    if (suggestions.value.length > 0) {
      highlightedIndex.value = (highlightedIndex.value + 1) % suggestions.value.length
    }
  }

  const onArrowUp = () => {
    if (suggestions.value.length > 0) {
      highlightedIndex.value = (highlightedIndex.value - 1 + suggestions.value.length) % suggestions.value.length
    }
  }

  const onEnter = () => {
    if (highlightedIndex.value >= 0 && highlightedIndex.value < suggestions.value.length) {
      selectSuggestion(suggestions.value[highlightedIndex.value])
    }
  }

  const onBlur = () => {
    setTimeout(() => {
      showSuggestions.value = false
    }, 100)
  }

  const locationFormatted = (item: SuggestionClient.Suggestion): void => {
    inputValue.value = params.displayValue ? params.displayValue(item) : `${item.location.placeName}, ${item.location.fullLocation}`
  }

  const onFocus = () => {
    if (inputValue.value.length >= params.minChars && suggestions.value.length > 0) {
      showSuggestions.value = true
    }
  }

  const onFocusOut = () => {
    setTimeout(() => {
      clearSelectedSuggestionIfNoResults()
      showSuggestions.value = false
    }, 100)
  }

  const onResetValue = () => {
    inputValue.value = ''
    isSuggestionSelected.value = false
    emit('update:modelValue', inputValue.value)
    emit('select', null)
  }

  const clearSelectedSuggestionIfNoResults = (): void => {
    if (inputValue.value.length >= params.minChars && !isSuggestionSelected.value && requestStatus.value === REQUEST_STATUS_LIST.SUCCESS) {
      emit('update:modelValue', inputValue.value)
      emit('select', null)
    }
  }

  return {
    inputValue,
    suggestions,
    showSuggestions,
    highlightedIndex,
    iconByType,
    isLoading,
    onInput,
    selectSuggestion,
    onArrowDown,
    onArrowUp,
    onEnter,
    onBlur,
    onFocus,
    onFocusOut,
    onResetValue
  }
}

export default useLocationAutocomplete
